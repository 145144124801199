:root {
  --main-bg: #cd1212;
  --navbar-bg: rgb(34, 32, 32);
}

body {
  margin: 0;
}

@import "styles/main.scss";
