.default-container {
  --main-bg: #393535;
  --navbar-bg: rgb(34, 32, 32);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: lighter;
  background-color: var(--main-bg);
  height: 100%;

  .navbar {
    width: 100%;
    background: var(--navbar-bg);
    color: white;
    display: flex;
    justify-content: space-between;

    .navbar-logo {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: white;
      .navbar-logo-img {
        height: 50px;
        margin: 10px;
      }
      .navbar-logo-text {
        padding-left: 10px;
        align-items: center;
        font-size: 1.2em;
      }
    }

    .navbar-items {
      display: flex;
      align-items: center;
      margin-right: 10px;

      ul {
        list-style: none;

        li {
          margin: 0 20 20 0;
          display: inline;

          span {
            // display: inline;
            &:after {
              content: "";
              // display: block;
              margin: auto;
              height: 2px;
              width: 0px;
              background: transparent;
              transition: width 0.5s ease, background-color 0.5s ease;
            }
            &:hover:after {
              width: 80%;
              background: white;
            }
          }
        }
      }
    }
  }
}

.default-container {
  .home-container {
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;
    p {
      font-size: x-large;
      text-align: justify;
      padding: 30px;
    }
  }
}
